import React from 'react'
import logo from '../logo.svg'
import { Link } from 'react-router-dom';

const HomeComponent = () => {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Hello SuperHosting!</p>
        <Link to="/testroute">Test route link</Link>
      </header>
    </div>
  )
}

export default HomeComponent
