import './App.css';
import { Routes, Route } from "react-router-dom";
import TestComponent from './components/Test';
import HomeComponent from './components/Home';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeComponent />}></Route>
        <Route path="testroute" element={<TestComponent />}></Route>
      </Routes>
    </>
  );
}

export default App;
